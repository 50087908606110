<!-- 联系我们 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text19 }}</h3>
      </div>
    </div>
    <!-- 地图 -->
    <div id="container_one">
      <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/biao.png" alt="" />
    </div>
    <div class="container_box">
      <h3 v-html="text1"></h3>
      <p>
        {{ text2 }}
      </p>
      <p>{{ text3 }}</p>
      <p>{{ text4 }}</p>
      <p>{{ text5 }}</p>
    </div>

    <!-- <div id="container_two">
      <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/biao.png" alt="" />
    </div>
    <div class="container_box box2">
      <h3>{{ text6 }}</h3>
      <p>{{ text7 }}</p>
      <p>{{ text6_2 }}</p>
      <p>{{ text4 }}</p>
      <p>{{ text5 }}</p>
    </div> -->
    
    <div id="container_three">
      <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/biao.png" alt="" />
    </div>
    <div class="container_box box3">
      <h3>{{ text8 }}</h3>
      <p>{{ text9 }}</p>
      <p>{{ text9_2 }}</p>
      <p>{{ text9_3 }}</p>
      <p>{{ text9_4 }}</p>
    </div>
    <!-- 留言板 -->
    <div class="message">
      <div>
        <h3>{{ text10 }}</h3>
        <p>{{ text11 }}</p>
        <ul class="list">
          <li>
            <div>
              <p>{{ text12 }}</p>
              <input type="text" />
            </div>
            <div>
              <p>{{ text13 }}</p>
              <input type="text" />
            </div>
            <div>
              <p>{{ text14 }}</p>
              <input type="text" />
            </div>
          </li>
          <li>
            <div>
              <p>{{ text15 }}</p>
              <input type="text" />
            </div>
            <div>
              <p>{{ text16 }}</p>
              <input type="text" />
            </div>
          </li>
          <li>
            <div>
              <p>{{ text17 }}</p>
              <textarea cols="24" rows="10"></textarea>
            </div>
          </li>
        </ul>
        <button class="btn">{{ text18 }}</button>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "研发中心及中试生产基地",
          text2: "地址:北京市大兴区中关村科技园区大兴生物医药产业基地宝参南街16号院1号楼2层201",
          text3: "邮编:102629",
          // text4: "电话:86-10-60709130",
          text5: "邮箱:hr@futuregenbiopharm.com",
          text6: "研发副中心",
          text6_2: "邮编:102206",
          text7: "地址:北京市昌平区科技园区科技综合楼",
          text8: "上海分公司",
          text9: "地址: 上海市静安区南京西路758号1503A室",
          text9_2: "邮编:200041",
          // text9_3: "电话:86-21-31185838",
          text9_4: "邮箱:hr@futuregenbiopharm.com",
          text10: "留言板",
          text11: "请留下相关资料，我们会及时联系您！",
          text12: "留言标题",
          text13: "电话",
          text14: "邮箱",
          text15: "姓名",
          text16: "公司",
          text17: "内容",
          text18: "提交",
          text19: "联系我们",
        };
        break;
      case "en":
        return {
          text1: "FutureGen Biopharmaceutical (Beijing) Co., Ltd <br/>Corporate, R&D Center, Pilot Plant.",
          text2: "Address: Room 201, Floor 2, Building 1, Yard 16, Baosan South Street, Daxing Biomedicine Industrial Base, Zhongguancun Science and Technology Park, Daxing District, Beijing ",
          text3: "Postcode: 102629",
          // text4: "Tel: +86-10-60709130",
          text5: "E-mail: hr@futuregenbiopharm.com",
          text6: "The Sub-R&D Center",
          text6_2: "Postcode: 102206",
          text7: "Address: Science and Technology Complex Building, Science and Technology Park, Changping District, Beijing ",
          text8: "Shanghai Branch",
          text9: "Room 1503A, No. 758 West Nanjing Road, Jing'an District, Shanghai",
          text9_2: "Postcode: 200041",
          // text9_3: "Tel: +86-21-31185838",
          text9_4: "E-mail: hr@futuregenbiopharm.com",
          text10: "Message Board",
          text11: "Please leave relevant information and we will contact you in time!",
          text12: "Title",
          text13: "Tel",
          text14: "E-mail",
          text15: "Name",
          text16: "Company",
          text17: "Content",
          text18: "Submit",
          text19: "Contact Us",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
.header {
  position: relative;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/lianxi_1.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
#container_one {
  position: relative;
  width: 100%;
  height: 400px;
  margin-top: 150px;
  z-index: -9;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/ditu_1.png") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 44%;
    left: 62%;
  }
}
#container_two {
  position: relative;
  width: 100%;
  height: 400px;
  margin-top: 200px;
  z-index: -9;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/ditu_2.png") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 50%;
    left: 47%;
  }
}
#container_three {
  position: relative;
  width: 100%;
  height: 400px;
  margin-top: 200px;
  z-index: -9;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/ditu_3.png") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 52%;
    left: 62%;
  }
}
.container_box {
  margin-top: -10%;
  margin-left: 13%;
  width: 650px;
  z-index: 999;
  padding: 50px;
  color: #fff;
  opacity: 0.7;
  background: rgba(42, 64, 151, 0.8);
  h3 {
    font-size: 28px;
    font-weight: normal;
  }
  p {
    width: 440px;
    font-size: 18px;
    line-height: 1.6;
    margin: 1vh 0;
  }
}
.box2 {
  margin-top: -10%;
  margin-left: 60%;
  z-index: 999;
}
.box3 {
  margin-top: -11%;
  margin-left: 13%;
  z-index: 999;
  margin-bottom: 200px;
}
.message {
  width: 100%;
  height: 999px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/lianxi/lianxi_2.png");
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      color: #fff;
      font-size: 50px;
      padding-top: 120px;
      text-align: center;
    }
    h3::after {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 50px;
      height: 2px;
      background-color: #ccc;
    }
    p {
      color: #fff;
      font-size: 18px;
      text-align: center;
      margin-top: 80px;
    }
    .list {
      list-style: none;
      display: flex;
      justify-content: space-between;
      li {
        margin-top: 100px;
        div {
          p {
            color: #fff;
            font-size: 14px;
            margin-top: 40px;
            margin-bottom: 10px;
          }
          input {
            width: 300px;
            height: 40px;
            outline: none;
            font-size: 24px;
            color: #fff;
            border: 1px solid #fff;
            background-color: transparent;
          }
          textarea {
            resize: none;
            color: #fff;
            outline: none;
            font-size: 22px;
            border: 1px solid #fff;
            background-color: transparent;
          }
        }
      }
    }
    .btn {
      width: 260px;
      height: 64px;
      border: none;
      cursor: pointer;
      text-align: center;
      line-height: 64px;
      font-size: 24px;
      color: #203864;
      margin-top: 100px;
      margin-left: calc(50% - 130px);
      -webkit-tap-highlight-color: transparent;
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  #container_one {
    width: 100vw;
    height: 200px;
    margin-top: 50px;
    background-size: cover;
    background-position: bottom;
    img {
      top: 44%;
      left: 80%;
      width: 12px;
    }
  }
  #container_two {
    width: 100vw;
    height: 200px;
    margin-top: 68px;
    background-size: cover;
    background-position: center;
    img {
      top: 50%;
      left: 43%;
      width: 12px;
    }
  }
  #container_three {
    width: 100vw;
    height: 200px;
    margin-top: 68px;
    background-size: cover;
    background-position: center;
    img {
      top: 54%;
      left: 81%;
      width: 12px;
    }
  }
  .container_box {
    margin: -20% auto 0 auto;
    width: 85vw;
    padding: 10px;
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    p {
      width: 100%;
      font-size: 12px;
    }
  }
  .message {
    width: 100%;
    margin-top: 5vh;
    div {
      width: 80vw;
      h3 {
        font-size: 26px;
        padding-top: 40px;
      }
      p {
        font-size: 14px;
        margin-top: 40px;
      }
      .list {
        flex-direction: column;
        li {
          margin-top: 10px;
          div {
            p {
              text-align: left;
              margin-top: 10px;
              margin-bottom: 0px;
            }
            input {
              width: 80vw;
              height: 30px;
              font-size: 14px;
            }
            textarea {
              width: 80vw;
              border: 1px solid #fff;
              background: transparent;
            }
          }
        }
      }
      .btn {
        width: 150px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        margin-left: calc(50% - 75px);
        margin-top: 50px;
      }
    }
  }
}
</style>
